@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  :global {
    .modalHeader {
      padding: pxToRem(20);
    }

    .modalContentMain {
      padding: 0;
    }
  }
}

.groupContent {
  height: auto;
}

.tabNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  /* Enable smooth scrolling on iOS */
  -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari, etc.) */
.tabNav::-webkit-scrollbar {
  display: none;
}

.container {
  margin: 0 auto;
}

.tabPanel {
  margin-top: pxToRem(16);
  padding: pxToRem(16);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: pxToRem(16);
}

.contentTitle {
  text-align: center;
}

.contentCard {
  img {
    width: 100%;
  }
}

@include sm {
  .root {
    header {
      h2 {
        @include fontSize(32, 32);
        font-weight: 800;
      }
    }
  }

  .contentCard {
    img {
      width: auto;
    }
  }
}
