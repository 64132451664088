@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin responsive-padding {
  padding-top: 32px;
  margin-bottom: 128px;

  @media (min-width: 960px) {
    flex-direction: row;
    padding-top: 40px;
  }
}

/* Root styles */
.root {
  @include flex-column;
  @include responsive-padding;
  justify-content: space-between;
}

/* Markdown content styling */
.markdownContent {
  h5 {
    margin-top: 0;
    margin-bottom: 12px;
  }

  h6 {
    margin-top: 0;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 12px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  strong {
    line-height: 32px;
    margin-bottom: 4px;
  }
}

/* Card styling */
.card {
  @include flex-column;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--color-neutral-150);
  background-color: var(--color-neutral-000);
  padding: 16px;
  max-width: 100%;
  margin-bottom: 32px;

  @media (min-width: 960px) {
    padding-bottom: 18px;
    padding-top: 28px;
    margin-bottom: 0;
    max-width: 299px;
  }

  &.markdownContent {
    p {
      margin-bottom: 56px;
      text-align: center;
    }
  }
}

/* Icon container */
.iconContainer {
  margin-bottom: 22px;
  width: 72px;
  height: 72px;

  .icon {
    width: 100%;
    height: 100%;
  }
}

.termsAndConditions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  flex-direction: row;

  a {
    font-size: pxToRem(14);
    color: var(--color-uiPrimary-600);
  }
}

.guaranteeMessage {
  text-align: center;
  h6 {
    margin-top: 0;
    margin-bottom: 8px;
  }

  p {
    margin: 0;
  }
}
