@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  padding-top: pxToRem(32);
  padding-bottom: pxToRem(32);
  @include sectionHorizontalSpacing();
  @include fullWidthBackgroundColor(var(--bac-tie2));

  .title {
    margin: 0 0 pxToRem(16);
  }

  .description {
    color: var(--tex-def-tie2);
    margin: auto;
    max-width: pxToRem(626);
  }
}

.header {
  text-align: center;
}

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  margin: auto;
  padding: pxToRem(40) 0 0;
  row-gap: pxToRem(32);
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  max-width: pxToRem(526);

  h4 {
    margin: pxToRem(16) 0 0;
    @include fontSize(17, 24);
    font-weight: 400;
  }

  p {
    margin: pxToRem(2) 0 0;
    @include fontSize(15, 20);
    font-weight: 400;
    color: var(--tex-def-tie2);
  }
}

@include md {
  .root {
    padding-top: pxToRem(56);
    padding-bottom: pxToRem(56);
  }

  .list {
    flex-direction: row;
    column-gap: pxToRem(32);
    max-width: pxToRem(1280);
  }

  .item {
    max-width: 33.33%;
    flex: 1;
  }
}
