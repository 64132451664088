@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.card {
  border: $border-2;
  border-radius: var(--cor_rad-lg);
  background: var(--bac-tie1);
  padding: pxToRem(16);
  margin-bottom: pxToRem(32);
  width: 100%;
}

.cardNoPurchased {
  border: $border-2;
  border-radius: var(--cor_rad-lg);
  background: var(--bac-tie1);
  margin-bottom: pxToRem(32);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: pxToRem(16);
}

.cardContent {
  display: flex;
  flex-direction: column;
  margin-bottom: pxToRem(16);
}

.cardNoPurchasedIcon {
  margin: pxToRem(20) 0;
  width: pxToRem(120);
  height: pxToRem(120);

  .icon {
    width: 100%;
    height: 100%;
  }
}

.lineBreak {
  border-top: $border-0;
  margin-top: pxToRem(40);
}

.text {
  @include fontSize(17, 24);
}

.cardLeftContent {
  margin-left: pxToRem(10);
  margin-bottom: pxToRem(24);
}

.carImg {
  width: 100%;
  height: auto;
}

.deliverySchedule {
  @include fontSize(12, 20);
  width: fit-content;
  border: $border-2;
  border-radius: pxToRem(20);
  padding: pxToRem(8) pxToRem(10);
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: pxToRem(18);
  pointer-events: none;
  letter-spacing: 0.1em;
}

.title {
  @include fontSize(26, 32);
}

.bold {
  font-weight: 800;
}

.priceLine {
  display: flex;
  justify-content: left;
}

.colorPrimary {
  color: $color-uiPrimary-600;
  padding-left: pxToRem(8);
  cursor: pointer;
  text-decoration: underline;
}

.icon {
  margin-right: pxToRem(10);
}

.menuButton {
  width: 100%;
  padding: pxToRem(4) 0;
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  :global(.button) {
    width: 100%;
  }
}

.paymentOption {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@include md {
  .card {
    padding: pxToRem(32);
    padding-bottom: pxToRem(26);
  }

  .cardContent {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: pxToRem(28);
  }

  .carImg {
    width: auto;
    height: pxToRem(128);
  }

  .menuButton {
    width: auto;
    padding: 0;
  }

  .paymentOption {
    flex-direction: row;
    gap: pxToRem(8);
  }

  .buttonBox {
    flex-direction: row;
  }

  .purchaseDetailButton {
    width: pxToRem(222);
  }

  .cardNoPurchasedContent {
    padding: pxToRem(24) pxToRem(16);
  }

  .cardLeftContent {
    margin-bottom: pxToRem(0);
  }
}
