@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  display: grid;
  row-gap: pxToRem(24);

  .displayNone {
    display: none;
  }
}

.topBlock {
  display: none;
}

.checkoutUSP {
  max-width: pxToRem(450);
  margin: auto;
}

.divider {
  display: none;
}

.reviewContainerLayout {
  display: grid;
  row-gap: pxToRem(8);
}

.reviewCard {
  border: none;
  padding: 0;
}

.reviewContentLayout {
  width: 100%;
  justify-content: space-between;
}

.reviewImageContainer {
  width: auto;
}

@include sm {
  .root {
    padding: 0;
  }

  .reviewContainerLayout {
    display: flex;
    gap: pxToRem(32);
    flex-direction: row;
    max-width: pxToRem(483);
    margin: auto;
    justify-content: space-around;
  }

  .reviewContentLayout {
    display: grid;
    row-gap: pxToRem(8);
    width: fit-content;
  }
}

@include mdp {
  .root {
    margin-top: pxToRem(16);
    padding: pxToRem(7) 0;
    display: grid;
    row-gap: pxToRem(12);
    border-radius: var(--cor_rad-xs);
    border: $border-2;
  }

  .topBlock {
    padding: pxToRem(7) pxToRem(16);
    margin: auto;
    display: flex;
    gap: pxToRem(8);
    justify-content: center;
  }

  .divider {
    height: pxToRem(1);
    background-color: $color-neutral-100;
    display: block;
  }

  .reviewContainerLayout {
    max-width: pxToRem(424);
    padding: pxToRem(8) 0;
  }

  .reviewContentLayout {
    width: fit-content;
  }
}
