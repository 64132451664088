@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  display: grid;
  grid-template-columns: pxToRem(52) auto;
  column-gap: pxToRem(8);
}

.icon {
  width: pxToRem(52);
  height: pxToRem(52);
}

.text {
  @include fontSize(15, 20);
  font-weight: 800;
  //New text color not yet exists in color.module.scss
  color: #332e69;
  display: flex;
  align-items: center;
}
