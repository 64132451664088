@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;

/**
 * Follows the technique found: https://www.mikeaparicio.com/posts/2025-01-23-styling-a-meter-element-with-css-and-svg/
 */
.stars {
  --background-color: #{$color-neutral-300};
  --icon-color: #{$color-star};
  --height: #{pxToRem(23)};
  --aspect-ratio: 115/23;
  --background-size: #{pxToRem(23)} #{pxToRem(23)};
  aspect-ratio: var(--aspect-ratio);
  height: var(--height);
  position: relative;
  transform: translate3d(0, 0, 0);

  meter {
    position: absolute;
    inset: 0;
    background: none;
    appearance: none;
    background-color: var(--background-color);
    height: calc(var(--height) - #{pxToRem(2)});
    width: auto;
    top: pxToRem(1);
    left: pxToRem(1);

    &::-moz-meter-bar {
      background: none;
      background-color: var(--icon-color);
    }
    &::-webkit-meter-bar {
      background: var(--background-color);
      border: 0;
      border-radius: 0;
      height: calc(var(--height) - #{pxToRem(2)});
    }
    &::-webkit-meter-optimum-value {
      background: var(--icon-color);
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 23 23'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M23 0H0v23h23V0ZM12.35 3.366a1 1 0 0 0-1.7 0L8.533 6.774a1 1 0 0 1-.61.443l-3.896.962a1 1 0 0 0-.524 1.615l2.586 3.068a1 1 0 0 1 .233.717l-.29 4.002a1 1 0 0 0 1.375.999l3.717-1.512a1 1 0 0 1 .754 0l3.717 1.512a1 1 0 0 0 1.374-.998l-.289-4.003a1 1 0 0 1 .233-.717l2.586-3.068a1 1 0 0 0-.524-1.615l-3.896-.962a1 1 0 0 1-.61-.443L12.35 3.366Z' clip-rule='evenodd'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-size: var(--background-size);
  }
}
